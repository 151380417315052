import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import React from 'react';
import * as Styled from './slpCouponsImageStyle';

const SlpCouponsImage = ({ sectionData }) => {
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <Styled.HeaderH1>{children}</Styled.HeaderH1>
      ),
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };
  return (
    <Styled.CircleBody $backgroundImage={sectionData?.image?.file.url}>
      {sectionData?.richText &&
        documentToReactComponents(
          JSON.parse(sectionData?.richText?.raw),
          optionsMainStyle
        )}
    </Styled.CircleBody>
  );
};

export default SlpCouponsImage;
