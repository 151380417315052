import styled from 'styled-components';

export const CircleBody = styled.div`
  width: 365px;
  height: 365px;
  margin: -180px auto -15px auto;
  padding: 75px 50px 50px 50px;
  font-family: 'Figtree-Regular', arial, sans-serif;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #fff;
  background: url(${(props) => props.$backgroundImage}) no-repeat top center;
  background-size: 100% auto;
  @media (max-width: 767px) {
    width: 250px;
    height: 250px;
    margin-top: -220px;
    margin-bottom: 0;
    padding: 50px 30px 30px 30px;
    font-size: 20px;
    line-height: 25px;
  }
`;

export const HeaderH1 = styled.h1`
  display: block;
  margin-top: 10px;
  font-family: 'Figtree-Bold', arial, sans-serif;
  font-size: 26px;
  line-height: 32px;
  color: #fec119;
  @media (max-width: 767px) {
    margin-top: 5px;
    font-size: 18px;
    line-height: 23px;
  }
`;
